import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import './styles.scss';

import { signExistentOrganization } from '../../components/sign/signService';
import { login } from '../../services/auth';
import { Growl } from 'primereact/growl';

function SignAccount() {
    const history = useHistory();
    const location = useLocation();

    let growl;

    const [deviceState, setDeviceState] = useState({
        deviceId: null,
        deviceName: null
    });

    const [authState, setAuthState] = useState({ username: null, password: null });

    useEffect(() => {
        let currentState = location.state;
        if (!currentState || !currentState.deviceId || !currentState.deviceName) {
            history.push('/');
            return;
        }
        setDeviceState({
            deviceId: currentState.deviceId,
            deviceName: currentState.deviceName
        });
        // eslint-disable-next-line
    }, []);


    function handleField(e) {
        setAuthState({
            ...authState,
            [e.target.name]: e.target.value
        });
    };

    function isFormValid() {
        return authState.username && authState.password;
    }

    function handleKeyPress(e) {
        if (e.key === 'Enter' && isFormValid())
            executeSignIn();
    }

    async function executeSignIn() {
        try {
            const response = await signExistentOrganization(deviceState, authState)
            login(response);
            history.push('/app');
        } catch (e) {
            onSignError(e);
        }
    }

    function onSignError(error) {
        let message = "Falha na execução!";
        if (!error || !error.response) {
            showErrorMessage(message);
            return;
        }
        const statusCode = error.response.status;
        if (statusCode === 404) {
            message = "Usuário não encontrado!";
            showErrorMessage(message);
            return;
        }
        if (statusCode === 403) {
            message = "Credenciais inválidas!";
            showErrorMessage(message);
            return;
        }
        if (statusCode === 409) {
            message = "Dispositivo já cadastrado!";
            showErrorMessage(message);
            return;
        }
        showErrorMessage(message);
    }

    function showErrorMessage(message) {
        let msg = { severity: 'error', summary: 'Aviso!', detail: message };
        growl.show(msg);
    }

    return (
        <div className="sign-account-background">
            <Growl ref={(el) => growl = el} />
            <div className="sign-account-container">
                <div className="p-col-12 p-md-6 p-lg-4 p-fluid contact-form">
                    <Panel>
                        <div className="p-grid-m" style={{ marginTop: '20px', marginBottom: '10px' }}>
                            <div className="p-grid p-justify-center">
                                <img className="logo-img" alt="Logo" src={process.env.PUBLIC_URL + "/assets/layout/images/fevermanagement.png"} />
                            </div>
                            <div className="header-title">
                                Ok, para finalizarmos
                            </div>
                            <div className="header-title header-bold">
                                precisamos apenas que você
                            </div>
                            <div className="header-title">
                                entre com suas credenciais de acesso
                            </div>
                            <div className="p-grid header-spacer">
                                <div className="p-col-12">
                                    <InputText type="text"
                                        autoFocus={true}
                                        name="username"
                                        placeholder="Usuário"
                                        onChange={handleField}
                                        onKeyPress={handleKeyPress}
                                        maxLength={200} />
                                </div>
                                <div className="p-col-12">
                                    <InputText type="password"
                                        name="password"
                                        placeholder="Senha"
                                        onChange={handleField}
                                        onKeyPress={handleKeyPress}
                                        maxLength={200} />
                                </div>
                                <div className="p-col-12">
                                    <Button type="button"
                                        label="Salvar"
                                        iconPos="right"
                                        icon="pi pi-save"
                                        disabled={!isFormValid()}
                                        onClick={executeSignIn} />
                                </div>
                            </div>
                        </div>
                    </Panel>
                </div>
            </div>
        </div>
    );
}

export default SignAccount;