import api from '../../services/api';
import { parseOrganization, parseExistentOrganization } from './signParser';

export const signNewOrganization = async (device, account, password) => {
    const response = await api.post('/Sign', parseOrganization(device, account, password));
    return response.data;
};

export const signExistentOrganization = async (deviceState, authState) => {
    const response = await api.post('/SignLogin', parseExistentOrganization(deviceState, authState));
    return response.data;
};
