import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import './styles.scss';
import { InputTextarea } from 'primereact/inputtextarea';

import { signNewOrganization } from '../../components/sign/signService';
import { login } from '../../services/auth';
import { Growl } from 'primereact/growl';

function SignAccount() {
    const history = useHistory();
    const location = useLocation();

    let growl;

    const [deviceState, setDeviceState] = useState({
        deviceId: null,
        deviceName: null
    });

    const [accountState, setAccountState] = useState({
        name: null,
        description: null,
        username: null,
    });

    const [passwordState, setPasswordState] = useState({
        password: null,
        passwordConfirmation: null,
    });

    useEffect(() => {
        let currentState = location.state;
        if (!currentState || !currentState.deviceId || !currentState.deviceName) {
            history.push('/');
            return;
        }
        setDeviceState({
            deviceId: currentState.deviceId,
            deviceName: currentState.deviceName
        });
        // eslint-disable-next-line
    }, []);

    function handleAccountField(e) {
        setAccountState({
            ...accountState,
            [e.target.name]: e.target.value
        });
    };

    function handlePasswordField(e) {
        setPasswordState({
            ...passwordState,
            [e.target.name]: e.target.value
        });
    };

    function isAccountFormValid() {
        return accountState.name && accountState.username && accountState.description;
    }

    function isPasswordFormValid() {
        return passwordState.password &&
            passwordState.passwordConfirmation &&
            passwordState.password.length > 5 &&
            passwordState.passwordConfirmation.length > 5 &&
            passwordState.password === passwordState.passwordConfirmation;
    }

    async function executeSignIn() {
        try {
            const response = await signNewOrganization(deviceState, accountState, passwordState)
            login(response);
            history.push('/app');
        } catch (e) {
            onSignError(e);
        }
    }


    function onSignError(error) {
        let message = "Falha na execução!";
        if (!error || !error.response) {
            showErrorMessage(message);
            return;
        }
        const statusCode = error.response.status;
        if (statusCode === 403) {
            message = "Organização já cadastrada com essa credencial, altere e tente novamente!";
            showErrorMessage(message);
            return;
        }
        if (statusCode === 409) {
            message = "Dispositivo já cadastrado!";
            showErrorMessage(message);
            return;
        }
        showErrorMessage(message);
    }

    function showErrorMessage(message) {
        let msg = { severity: 'error', summary: 'Aviso!', detail: message };
        growl.show(msg);
    }

    function handleKeyPress(e) {
        if (e.key === 'Enter' && isAccountFormValid() && isPasswordFormValid())
            executeSignIn();
    }

    return (
        <div className="sign-account-background">
            <Growl ref={(el) => growl = el} />
            <div className="sign-account-container">
                <div className="p-col-12 p-md-6 p-lg-4 p-fluid contact-form">
                    <Panel>
                        <div className="p-grid-m" style={{ marginTop: '20px', marginBottom: '10px' }}>
                            <div className="header-title">
                                Para finalizar, precisamos de
                            </div>
                            <div className="header-title header-bold">
                                mais alguns dados
                            </div>
                            <div className="header-title header-spacer">
                                Primeiramente, entre com
                            </div>
                            <div className="header-title">
                                seu o nome e descrição de sua organização
                            </div>
                            <div className="p-grid header-spacer">
                                <div className="p-col-12 p-md-12 p-sm-12">
                                    <InputText
                                        autoFocus={true}
                                        className="p-col-12"
                                        type="text"
                                        name="name"
                                        value={accountState.name || ""}
                                        onChange={handleAccountField}
                                        onKeyPress={handleKeyPress}
                                        placeholder="Nome"
                                        maxLength={200}
                                    />
                                </div>
                                <div className="p-col-12 p-md-12 p-sm-12">
                                    <InputTextarea name="description"
                                        className="p-col-12"
                                        rows={3}
                                        cols={30}
                                        placeholder="Descrição"
                                        value={accountState.description || ""}
                                        onChange={handleAccountField}
                                        onKeyPress={handleKeyPress}
                                        autoResize={true}
                                        maxLength={200} />
                                </div>
                            </div>

                            <div className="header-title header-spacer">
                                E para terminar, precisamos que
                            </div>
                            <div className="header-title header-bold">
                                você defina um usuário e senha
                            </div>
                            <div className="header-title">
                                para acessar ao sistema
                            </div>
                            <div className="p-grid header-spacer">
                                <div className="p-col-12 p-md-6 p-sm-12">
                                    <InputText
                                        className="p-col-12"
                                        type="text"
                                        name="username"
                                        onChange={handleAccountField}
                                        onKeyPress={handleKeyPress}
                                        value={accountState.username || ""}
                                        placeholder="Usuário"
                                        maxLength={200}
                                    />
                                </div>
                                <div className="p-col-12 p-md-6 p-sm-12">
                                    <InputText
                                        className="p-col-12"
                                        type="password"
                                        name="password"
                                        onChange={handlePasswordField}
                                        onKeyPress={handleKeyPress}
                                        value={passwordState.password || ""}
                                        placeholder="Senha"
                                        maxLength={200}
                                    />
                                </div>
                            </div>

                            <div className="p-grid">
                                <div className="p-col-12 p-md-6 p-sm-12">
                                    <InputText
                                        className="p-col-12"
                                        type="password"
                                        name="passwordConfirmation"
                                        value={passwordState.passwordConfirmation || ""}
                                        onChange={handlePasswordField}
                                        onKeyPress={handleKeyPress}
                                        placeholder="Confirmação"
                                        maxLength={200}
                                    />
                                </div>

                                <div className="p-col-12 p-md-6 p-sm-12">
                                    <Button
                                        type="button"
                                        label="Salvar"
                                        icon="pi pi-save"
                                        onClick={executeSignIn}
                                        disabled={!isAccountFormValid() || !isPasswordFormValid()}
                                        iconPos="right" />
                                </div>
                            </div>
                        </div>
                    </Panel>
                </div>
            </div>
        </div>
    );
}

export default SignAccount;