import React from 'react';
function AppFooter() {
    return (
        <div className="layout-footer">
            <span className="footer-text" style={{ 'marginRight': '5px' }}>Fever Management</span>
            <img src={process.env.PUBLIC_URL + "/assets/layout/images/fevermanagement.png"} alt="" width="60" />
        </div>
    );
}

export default AppFooter;