import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';

import { getUserParsed } from '../../services/auth';
import { updateProfileAccount, updateProfilePassword } from '../../components/account/accountService';
import { login } from '../../services/auth';

import './styles.scss';

function Account() {

    const [profileState, setProfileState] = useState({
        name: null,
        description: null,
        username: null,
    });

    const [passwordState, setPasswordState] = useState({
        oldPassword: null,
        password: null,
        passwordConfirmation: null,
    });
    let growl;

    useEffect(() => {
        populateCurrentUser();
        // eslint-disable-next-line
    }, []);

    function populateCurrentUser() {
        let currentUser = getUserParsed();
        setProfileState({
            name: currentUser.Name,
            description: currentUser.Description,
            username: currentUser.Username,
        });
    }

    function handleProfileField(e) {
        setProfileState({
            ...profileState,
            [e.target.name]: e.target.value
        });
    };

    function isProfileFormValid() {
        return profileState.name && profileState.username && profileState.description;
    }

    function isPasswordFormValid() {
        return passwordState.oldPassword &&
            passwordState.password &&
            passwordState.passwordConfirmation &&
            passwordState.password.length > 5 &&
            passwordState.passwordConfirmation.length > 5 &&
            passwordState.password === passwordState.passwordConfirmation;
    }

    function handlePasswordField(e) {
        setPasswordState({
            ...passwordState,
            [e.target.name]: e.target.value
        });
    };

    async function executeUpdateProfile() {
        try {
            const response = await updateProfileAccount(profileState.name, profileState.name, profileState.username);
            login(response);
            showSuccessMessage("Perfil atualizado com sucesso!");
        } catch (e) {
            onUpdateProfileError(e);
        }
    }

    async function executeUpdatePassword() {
        try {
            const response = await updateProfilePassword(passwordState.oldPassword, passwordState.password);
            login(response);
            showSuccessMessage("Senha atualizada com sucesso!");
            setPasswordState({
                oldPassword: "",
                password: "",
                passwordConfirmation: "",
            });
        } catch (e) {
            onUpdateProfileError(e);
        }
    }


    function onUpdateProfileError(error) {
        let message = "Falha na execução!";
        if (!error || !error.response) {
            showErrorMessage(message);
            return;
        }
        const statusCode = error.response.status;
        if (statusCode === 404) {
            message = "Usuário não encontrado!";
            showErrorMessage(message);
            return;
        }
        if (statusCode === 403) {
            message = "Credenciais inválidas!";
            showErrorMessage(message);
            return;
        }
        if (statusCode === 409) {
            message = "Organização já cadastrada com essa credencial, altere e tente novamente!";
            showErrorMessage(message);
            return;
        }
        showErrorMessage(message);
    }

    function showErrorMessage(message) {
        let msg = { severity: 'error', summary: 'Aviso!', detail: message };
        growl.show(msg);
    }

    function showSuccessMessage(message) {
        let msg = { severity: 'success', summary: 'Sucesso!', detail: message };
        growl.show(msg);
    }


    return (
        <div className="p-grid">
            <Growl ref={(el) => growl = el} />
            <div className="p-col-12">
                <div className="card">
                    <h1>Sua Conta</h1>
                    <div className="p-grid">
                        <div className="p-col-12 p-md-3">
                            <InputText
                                className="p-col-12"
                                type="text"
                                autoFocus={true}
                                name="username"
                                onChange={handleProfileField}
                                value={profileState.username || ""}
                                placeholder="Usuário"
                            />
                        </div>
                        <div className="p-col-12 p-md-9">
                            <InputText
                                className="p-col-12"
                                type="text"
                                autoFocus={true}
                                name="name"
                                value={profileState.name || ""}
                                onChange={handleProfileField}
                                placeholder="Nome"
                            />
                        </div>
                        <div className="p-col-12 p-md-12">
                            <InputTextarea name="description"
                                className="p-col-12"
                                rows={3}
                                cols={30}
                                placeholder="Descrição"
                                value={profileState.description || ""}
                                onChange={handleProfileField}
                                autoResize={true} />
                        </div>
                        <div className="p-col-12">
                            <Button
                                type="button"
                                disabled={!isProfileFormValid()}
                                onClick={executeUpdateProfile}
                                label="Salvar" />
                        </div>
                    </div>
                </div>

                <div className="card">
                    <h1>Alterar Senha</h1>
                    <div className="p-grid">
                        <div className="p-col-12 p-md-3">
                            <InputText
                                className="p-col-12"
                                type="password"
                                autoFocus={true}
                                name="oldPassword"
                                onChange={handlePasswordField}
                                value={passwordState.oldPassword || ""}
                                placeholder="Senha Atual"
                            />
                        </div>
                        <div className="p-col-12 p-md-3">
                            <InputText
                                className="p-col-12"
                                type="password"
                                autoFocus={true}
                                name="password"
                                onChange={handlePasswordField}
                                value={passwordState.password || ""}
                                placeholder="Nova senha"
                            />
                        </div>
                        <div className="p-col-12 p-md-3">
                            <InputText
                                className="p-col-12"
                                type="password"
                                autoFocus={true}
                                name="passwordConfirmation"
                                value={passwordState.passwordConfirmation || ""}
                                onChange={handlePasswordField}
                                placeholder="Confirmação"
                            />
                        </div>
                        <div className="p-col-12">
                            <Button type="button"
                                disabled={!isPasswordFormValid()}
                                onClick={executeUpdatePassword}
                                label="Alterar senha"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Account;