export const parseOrganization = (device, account, password) => {
    return {
        DeviceId: device.deviceId,
        DeviceName: device.deviceName,
        AccountName: account.name,
        AccountDescription: account.description,
        AccountUsername: account.username,
        AccountPassword: password.password,
    };
}

export const parseExistentOrganization = (device, auth) => {
    return {
        DeviceId: device.deviceId,
        DeviceName: device.deviceName,
        AccountUsername: auth.username,
        AccountPassword: auth.password,
    };
}