import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Growl } from 'primereact/growl';

import { authenticateUser } from '../../components/login/loginService';
import { login, logout } from '../../services/auth';

import './styles.scss';

function Login() {
    const history = useHistory();
    const [authState, setAuthState] = useState({ username: null, password: null });
    let growl;

    useEffect(() => {
        logout();
        // eslint-disable-next-line
    }, []);

    async function executeAuthenticate() {
        try {
            const response = await authenticateUser(authState.username, authState.password);
            login(response);
            history.push('/app');
        } catch (e) {
            onAuthError(e);
        }
    }

    function onAuthError(error) {
        let message = "Falha na execução!";
        if (!error || !error.response) {
            showErrorMessage(message);
            return;
        }
        const statusCode = error.response.status;
        if (statusCode === 404) {
            message = "Usuário não encontrado!";
            showErrorMessage(message);
            return;
        }
        if (statusCode === 403) {
            message = "Credenciais inválidas!";
            showErrorMessage(message);
            return;
        }
        showErrorMessage(message);
    }

    function showErrorMessage(message) {
        let msg = { severity: 'error', summary: 'Aviso!', detail: message };
        growl.show(msg);
    }

    function handleField(e) {
        setAuthState({
            ...authState,
            [e.target.name]: e.target.value
        });
    };

    function isFormValid() {
        return authState.username && authState.password;
    }

    function handleKeyPress(e) {
        if (e.key === 'Enter' && isFormValid())
            executeAuthenticate();
    }

    return (
        <div className="login-background">
            <Growl ref={(el) => growl = el} />
            <div className="login-container">
                <div className="p-col-12 p-md-6 p-lg-4 p-fluid contact-form">
                    <Panel>
                        <div className="p-grid-m" style={{ marginTop: '20px', marginBottom: '20px' }}>
                            <div className="p-grid p-justify-center">
                                <img className="logo-img" alt="Logo" src={process.env.PUBLIC_URL + "/assets/layout/images/fevermanagement.png"} />
                            </div>
                            <div className="p-col-12">
                                <InputText type="text"
                                    autoFocus={true}
                                    name="username"
                                    placeholder="Usuário"
                                    onChange={handleField}
                                    onKeyPress={handleKeyPress}
                                    maxLength={200} />
                            </div>
                            <div className="p-col-12">
                                <InputText type="password"
                                    name="password"
                                    placeholder="Senha"
                                    onChange={handleField}
                                    onKeyPress={handleKeyPress}
                                    maxLength={200} />
                            </div>
                            <div className="p-col-12">
                                <Button type="button"
                                    label="Entrar"
                                    disabled={!isFormValid()}
                                    onClick={executeAuthenticate} />
                            </div>
                        </div>
                    </Panel>
                </div>
            </div>
        </div>
    );
}

export default Login;