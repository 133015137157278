import api from '../../services/api';
import { parseDeviceToUpdate } from './devicesParser';

export const getDevicesData = async () => {
    const response = await api.get('/devices');
    return response.data;
};

export const deleteDevice = async (deviceId) => {
    const response = await api.delete('/devices/' + deviceId);
    return response;
};

export const updateDeviceDescription = async (deviceSelected) => {
    const response = await api.put('/devices', parseDeviceToUpdate(deviceSelected));
    return response;
};

