import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";
import { logout } from './services/auth';
function AppTopbar({ onToggleMenu }) {

    const history = useHistory();
    function onClickLogout() {
        logout();
        history.push('/');
    }

    return (
        <div className="layout-topbar clearfix">
            <button className="p-link layout-menu-button" onClick={onToggleMenu}>
                <span className="pi pi-bars" />
            </button>
            <div className="layout-topbar-icons">
                <button className="p-link">
                    <span className="layout-topbar-item-text">Settings</span>
                    <span onClick={onClickLogout} className="layout-topbar-icon pi pi-power-off" />
                </button>
            </div>
        </div>
    );
}

AppTopbar.propTypes = {
    onToggleMenu: PropTypes.func.isRequired
}

export default AppTopbar;