import React, { useState } from 'react';
import classNames from 'classnames';
import { useHistory } from "react-router-dom";

import { logout } from './services/auth';

function AppProfile() {

    const [expandedState, setExpandedState] = useState(true);
    const history = useHistory();

    function onClick(e) {
        setExpandedState(!expandedState);
        e.preventDefault();
    }

    function onClickLogout() {
        logout();
        history.push('/');
    }

    function onClickAccount() {
        history.push('/app/account');
    }

    return (
        <div className="layout-profile">
            <div>
                <img src={process.env.PUBLIC_URL + "/assets/layout/images/profile_male.png"} alt="" />
            </div>
            <button className="p-link layout-profile-link" onClick={onClick}>
                <i className="pi pi-fw pi-cog" />
            </button>
            <ul className={classNames({ 'layout-profile-expanded': expandedState })}>
                <li><button className="p-link" onClick={onClickAccount}><i className="pi pi-fw pi-user" /><span>Perfil</span></button></li>
                <li><button className="p-link" onClick={onClickLogout}><i className="pi pi-fw pi-power-off" /><span>Sair</span></button></li>
            </ul>
        </div>
    );
}
export default AppProfile;
