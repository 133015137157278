import React, { useState } from 'react';
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types';
import classNames from 'classnames';

function AppSubmenu({
    className,
    items,
    onMenuItemClick,
    root
}) {

    const [activeIndexState, setActiveIndexState] = useState(null);

    function currentOnMenuItemClick(event, item, index) {
        //avoid processing disabled items
        if (item.disabled) {
            event.preventDefault();
            return true;
        }

        //execute command
        if (item.command) {
            item.command({ originalEvent: event, item: item });
        }

        if (index === activeIndexState)
            setActiveIndexState(null);
        else
            setActiveIndexState(index);

        if (onMenuItemClick) {
            onMenuItemClick({
                originalEvent: event,
                item: item
            });
        }
    }

    function renderLinkContent(item) {
        let submenuIcon = item.items && <i className="pi pi-fw pi-angle-down menuitem-toggle-icon"></i>;
        let badge = item.badge && <span className="menuitem-badge">{item.badge}</span>;

        return (
            <React.Fragment>
                <i className={item.icon}></i>
                <span>{item.label}</span>
                {submenuIcon}
                {badge}
            </React.Fragment>
        );
    }

    function renderLink(item, i) {
        let content = renderLinkContent(item);

        if (item.to) {
            return (
                <NavLink activeClassName="active-route" to={item.to} onClick={(e) => currentOnMenuItemClick(e, item, i)} exact target={item.target}>
                    {content}
                </NavLink>
            )
        }
        return (
            <a href={item.url} onClick={(e) => currentOnMenuItemClick(e, item, i)} target={item.target}>
                {content}
            </a>
        );
    }

    let currentItems = items && items.map((item, i) => {
        let active = activeIndexState === i;
        let styleClass = classNames(item.badgeStyleClass, { 'active-menuitem': active && !item.to });

        return (
            <li className={styleClass} key={i}>
                {item.items && root === true && <div className='arrow'></div>}
                {renderLink(item, i)}
                <AppSubmenu items={item.items} onMenuItemClick={onMenuItemClick} />
            </li>
        );
    });

    return currentItems ? <ul className={className}>{currentItems}</ul> : null;
}

AppSubmenu.propTypes = {
    className: PropTypes.string,
    items: PropTypes.array,
    onMenuItemClick: PropTypes.func,
    root: PropTypes.bool
}

function AppMenu({ model, onMenuItemClick }) {
    return (
        <div className="layout-menu-container">
            <AppSubmenu items={model} className="layout-menu" onMenuItemClick={onMenuItemClick} root={true} />
        </div>
    );
}

AppMenu.propTypes = {
    model: PropTypes.array,
    onMenuItemClick: PropTypes.func
}

export default AppMenu;