import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import App from "../App";
import Login from "../pages/Login";
import Sign from "../pages/Sign";
import SignAccount from "../pages/SignAccount";
import SignLogin from "../pages/SignLogin";

import { isAuthenticated } from "../services/auth";

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props => isAuthenticated() ? (<Component {...props} />) : (<Redirect to={{ pathname: "/", state: { from: props.location } }} />)}
    />
);

const Routes = () => (
    <BrowserRouter>
        <Switch>
            <Route exact path="/" component={() => <Login />} />
            <Route exact path="/sign/:device" component={() => <Sign />} />
            <Route exact path="/signaccount" component={() => <SignAccount />} />
            <Route exact path="/signlogin" component={() => <SignLogin />} />
            <PrivateRoute path="/app" component={() => <App />} />
            <Route path="*" component={() => <Redirect to="/" />} />
        </Switch>
    </BrowserRouter>
);

export default Routes;