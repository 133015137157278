import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';

import { getDashboardData } from '../../components/dashboard/dashboardService';

import './styles.scss';
import { Growl } from 'primereact/growl';

function Dashboard() {

    let growl;

    const [devicesAmountState, setDevicesAmountState] = useState(0);

    const [graphicTotalsEntityState, setGraphicTotalsEntityState] = useState({
        totalAmount: 0,
        normalAmount: 0,
        feverAmount: 0
    });

    const [graphicTwoWeeksEntityState, setGraphicTwoWeeksEntityState] = useState({
        totalList: [],
        normalList: [],
        feverList: []
    });

    const [lineChartState, setLineChartState] = useState({
        labels: [],
        datasets: [
            {
                label: 'Medições totais',
                data: [],
                fill: false,
                borderColor: '#20D077'
            },
            {
                label: 'Medições - Febre',
                data: [],
                fill: false,
                borderColor: '#EF6262'
            },
            {
                label: 'Medições - Sem febre',
                data: [],
                fill: false,
                borderColor: '#116FBF'
            }
        ]
    } | null);

    const [pieChartState, setPieChartState] = useState({
        labels: ['Normal', 'Febre'],
        datasets: [
            {
                data: [0, 0],
                backgroundColor: [
                    "#116FBF",
                    "#EF6262",
                ],
                hoverBackgroundColor: [
                    "#FFE082",
                    "#81D4FA",
                ]
            }]
    } | null);

    useEffect(() => {
        populateDashboardData();
        // eslint-disable-next-line
    }, []);

    async function populateDashboardData() {
        try {
            let dashboardData = await getDashboardData();
            setDevicesAmountState(dashboardData.devicesAmount);
            setGraphicTotalsEntityState(dashboardData.graphicTotalsEntity);
            setGraphicTwoWeeksEntityState(dashboardData.graphicTwoWeeksEntity);
            parseLineChart(dashboardData.graphicTwoWeeksEntity);
            parsePieChart(dashboardData.graphicTotalsEntity);
        } catch (e) {
            showErrorMessage("Falha na execução, tente novamente!");
        }
    }

    function showErrorMessage(message) {
        let msg = { severity: 'error', summary: 'Aviso!', detail: message };
        growl.show(msg);
    }

    function parseLineChart(graphicTwoWeeksEntity) {

        let labels = graphicTwoWeeksEntity.totalList.map(item => item.dateText);
        let totalData = graphicTwoWeeksEntity.totalList.map(item => item.amount);
        let feverData = graphicTwoWeeksEntity.feverList.map(item => item.amount);
        let normalData = graphicTwoWeeksEntity.normalList.map(item => item.amount);

        setLineChartState({
            labels: labels,
            datasets: [
                {
                    label: 'Medições totais',
                    data: totalData,
                    fill: false,
                    borderColor: '#20D077'
                },
                {
                    label: 'Medições - Febre',
                    data: feverData,
                    fill: true,
                    borderColor: '#EF6262'
                },
                {
                    label: 'Medições - Sem febre',
                    data: normalData,
                    fill: false,
                    borderColor: '#116FBF'
                }
            ]
        });
    }

    function parsePieChart(graphicTotalsEntity) {
        if (!graphicTotalsEntity)
            return;
        setPieChartState({
            labels: ['Normal', 'Febre'],
            datasets: [
                {
                    data: [graphicTotalsEntity.normalAmount, graphicTotalsEntity.feverAmount],
                    backgroundColor: [
                        "#116FBF",
                        "#EF6262",
                    ],
                    hoverBackgroundColor: [
                        "#5B92BF",
                        "#EA9393",
                    ]
                }]
        });
    }

    return (
        <div className="p-grid p-fluid dashboard">
            <Growl ref={(el) => growl = el} />
            <div className="p-col-12 p-lg-4">
                <div className="card summary">
                    <span className="title">Medições</span>
                    <span className="detail">Total de medições realizadas</span>
                    <span className="count total-measurement">{graphicTotalsEntityState.totalAmount}</span>
                </div>
            </div>
            <div className="p-col-12 p-lg-4">
                <div className="card summary">
                    <span className="title">Febre</span>
                    <span className="detail">Medições que detectaram febre</span>
                    <span className="count fever">{graphicTotalsEntityState.feverAmount}</span>
                </div>
            </div>
            <div className="p-col-12 p-lg-4">
                <div className="card summary">
                    <span className="title">Dispositivos</span>
                    <span className="detail">Número de dispositivos</span>
                    <span className="count devices">{devicesAmountState}</span>
                </div>
            </div>

            <div className="p-col-12 p-lg-6">
                <div className="card">
                    <h1 className="centerText">Medições - Últimos 14 dias</h1>
                    {graphicTwoWeeksEntityState && lineChartState && <Chart type="line" data={lineChartState} height="150" />}
                </div>
            </div>

            <div className="p-col-12 p-lg-6">
                <div className="card">
                    <h1 className="centerText">Medições - Normais / Febre</h1>
                    {pieChartState && <Chart type="pie" data={pieChartState} height="150" />}
                </div>
            </div>


        </div>
    );
}

export default Dashboard;