import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import './styles.scss';

function Sign() {
    const history = useHistory();

    const { device } = useParams();

    const [deviceNameState, setDeviceNameState] = useState(null);
    const [deviceIdState, setDeviceIdState] = useState(null);

    useEffect(() => {
        if (!device || (device && device.length !== 36)) {
            history.push('/');
            return;
        }
        setDeviceIdState(device);
        // eslint-disable-next-line
    }, []);

    function handleDeviceName(e) {
        setDeviceNameState(e.target.value);
    };

    function continueExistentSignIn() {
        history.push({
            pathname: '/signlogin',
            state: {
                deviceId: deviceIdState,
                deviceName: deviceNameState
            }
        });
    }

    function continueSignIn() {
        history.push({
            pathname: '/signaccount',
            state: {
                deviceId: deviceIdState,
                deviceName: deviceNameState
            }
        });
    }

    function isDeviceNameValid() {
        return deviceNameState && deviceNameState.length > 5;
    }

    return (
        <div className="sign-background">
            <div className="sign-container">
                <div className="p-col-12 p-md-6 p-lg-4 p-fluid contact-form">
                    <Panel>
                        <div className="p-grid-m" style={{ marginTop: '20px', marginBottom: '10px' }}>
                            <div className="p-grid p-justify-center">
                                <img className="logo-img" alt="Logo" src={process.env.PUBLIC_URL + "/assets/layout/images/fevermanagement.png"} />
                            </div>
                            <div className="header-title">
                                Bem-vindo ao cadastro do
                            </div>
                            <div className="header-title header-bold">
                                Fever Management
                            </div>
                            <div className="header-title header-spacer">
                                Em primeiro lugar,
                            </div>
                            <div className="header-title">
                                entre com um nome para
                            </div>
                            <div className="header-title">
                                o dispositivo
                            </div>

                            <div className="p-grid header-spacer">
                                <div className="p-col-12 p-md-12 p-sm-12">
                                    <InputText
                                        className="p-col-12"
                                        type="text"
                                        autoFocus={true}
                                        name="name"
                                        placeholder="Nome"
                                        onChange={handleDeviceName}
                                        value={deviceNameState || ""}
                                        maxLength={200}
                                    />
                                </div>
                            </div>
                            <div className="header-title header-spacer">
                                Também precisamos saber
                            </div>
                            <div className="header-title">
                                se você já possui cadastro
                            </div>
                            <div className="p-grid header-spacer">
                                <div className="p-col-12 p-md-6 p-sm-12">
                                    <Button
                                        type="button"
                                        label="Já tenho conta"
                                        icon="pi pi-chevron-right"
                                        onClick={continueExistentSignIn}
                                        disabled={!isDeviceNameValid()}
                                        iconPos="right" />
                                </div>
                                <div className="p-col-12 p-md-6 p-sm-12">
                                    <Button
                                        type="button"
                                        label="Sou novo aqui"
                                        icon="pi pi-chevron-right"
                                        onClick={continueSignIn}
                                        disabled={!isDeviceNameValid()}
                                        iconPos="right" />
                                </div>
                            </div>
                        </div>
                    </Panel>
                </div>
            </div>
        </div>
    );
}

export default Sign;