import JWT from 'jwt-decode';

export const SESSIONIDENTIFIER = "@fevermanagement";

export const isAuthenticated = () => {
    return sessionStorage.getItem(SESSIONIDENTIFIER) !== null;
};

export const getToken = () => {
    return sessionStorage.getItem(SESSIONIDENTIFIER);
};

export const login = token => {
    sessionStorage.setItem(SESSIONIDENTIFIER, token);
};

export const logout = () => {
    sessionStorage.removeItem(SESSIONIDENTIFIER);
};

export const getUserParsed = () => {
    let session = sessionStorage.getItem(SESSIONIDENTIFIER);
    if (!session)
        return null;
    let jwtConverted = JWT(session);
    return JSON.parse(jwtConverted.fevermanagement);
}