export const parseAccount = (name, description, username) => {
    return {
        Name: name,
        Username: username,
        Description: description
    };
}

export const parsePasswordAccount = (oldPassword, password) => {
    return {
        OldPassword: oldPassword,
        Password: password,
    };
}