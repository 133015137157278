import React, { useEffect, useState } from 'react';
import { DataView } from 'primereact/dataview';
import './styles.scss';
import { Button } from 'primereact/button';
import { getDevicesData, deleteDevice, updateDeviceDescription } from '../../components/devices/devicesServices';
import { Growl } from 'primereact/growl';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';

const header = (
    <div className="p-col-12 p-md-12 p-sm-12 p-align-center" style={{ textAlign: 'left', verticalAlign: 'baseline' }}>
        Descrição
    </div>
);

function Devices() {

    const [devicesState, setDevicesState] = useState([]);
    const [deviceModalState, setDeviceModalState] = useState(false);
    const [deviceSelectedState, setDeviceSelectedState] = useState({
        deviceId: null,
        deviceDescription: null
    });
    // eslint-disable-next-line
    let dv, growl;
    useEffect(() => {
        getDevices();
        // eslint-disable-next-line
    }, []);

    async function getDevices() {
        try {
            let devicesData = await getDevicesData();
            setDevicesState(devicesData);
        } catch (e) {
            showErrorMessage("Falha na execução, tente novamente!");
        }
    }

    function showErrorMessage(message) {
        let msg = { severity: 'error', summary: 'Aviso!', detail: message };
        growl.show(msg);
    }

    function showSuccessMessage(message) {
        let msg = { severity: 'success', summary: 'Sucesso!', detail: message };
        growl.show(msg);
    }

    function itemRow(device) {
        if (!device)
            return (<></>);
        return (
            <div className="p-grid" style={{ padding: '0.5em', borderBottom: '1px solid #d9d9d9' }}>

                <div className="p-col-12 p-md-10 p-sm-12">
                    {device && <div className="p-col-12">{device.description}</div>}
                </div>
                <div className="p-col-12 p-md-2 p-grid p-align-center p-justify-end">
                    <Button onClick={() => showUpdateDialog(device)} icon="pi pi-pencil" style={{ marginLeft: '.25em' }} />
                    <Button onClick={() => executeDeleteDevice(device.id)} icon="pi pi-times" style={{ marginLeft: '.25em' }} className="p-button-danger" />
                </div>
            </div>
        );
    }

    function showUpdateDialog(device) {
        setDeviceSelectedState({
            deviceId: device.id,
            deviceDescription: device.description
        });
        setDeviceModalState(true);
    }

    async function executeDeleteDevice(deviceId) {
        try {
            await deleteDevice(deviceId);
            getDevices();
            showSuccessMessage("Dispositivo removido com sucesso!");
        } catch (e) {
            showErrorMessage("Falha ao excluir dispositivo, tente novamente!");
        }
    }

    function clearDeviceSelected() {
        setDeviceSelectedState({
            deviceId: null,
            deviceDescription: null
        });
        setDeviceModalState(false);
    }

    function handleDeviceSelectedField(e) {
        setDeviceSelectedState({
            ...deviceSelectedState,
            [e.target.name]: e.target.value
        });
    };

    function handleKeyPress(e) {
        if (e.key === 'Enter' && deviceSelectedState && deviceSelectedState.deviceDescription)
            executeUpdateDeviceDescription();
    }

    async function executeUpdateDeviceDescription() {
        try {
            await updateDeviceDescription(deviceSelectedState);
            showSuccessMessage("Dispositivo atualizado com sucesso!");
            setDeviceModalState(false);
            getDevices();
        } catch (e) {
            onRequestError(e);
        }
    }

    function onRequestError(error) {
        let message = "Falha na execução!";
        if (!error || !error.response) {
            showErrorMessage(message);
            return;
        }
        const statusCode = error.response.status;
        if (statusCode === 403) {
            message = "Credenciais inválidas!";
            showErrorMessage(message);
            return;
        }
        showErrorMessage(message);
    }

    const dialogFooter = (
        <div className="p-grid">
            <div className="p-col-12 p-md-12 p-sm-12">
                <Button
                    icon="pi pi-save"
                    style={{ marginTop: '.25em' }}
                    iconPos="right"
                    onClick={executeUpdateDeviceDescription}
                    disabled={!deviceSelectedState || !deviceSelectedState.deviceDescription}
                    label="Salvar" />
            </div>
            <div className="p-col-12 p-md-12 p-sm-12">
                <Button
                    onClick={() => clearDeviceSelected()}
                    icon="pi pi-times"
                    style={{ marginTop: '.25em' }}
                    iconPos="right"
                    label="Cancelar"
                    className="p-button-danger" />
            </div>
        </div>
    );

    return (
        <div className="p-grid">
            <Growl ref={(el) => growl = el} />
            <Dialog
                header="Alterar"
                visible={deviceModalState}
                modal={true}
                width={400}
                footer={dialogFooter}
                onHide={() => clearDeviceSelected()}>
                <div className="p-grid">
                    <div className="p-col-12 p-md-12 p-sm-12">
                        <InputText
                            className="p-col-12"
                            type="text"
                            autoFocus={true}
                            name="deviceDescription"
                            value={deviceSelectedState.deviceDescription || ""}
                            onChange={handleDeviceSelectedField}
                            onKeyPress={handleKeyPress}
                            placeholder="Nome"
                            maxLength={200}
                        />
                    </div>
                </div>
            </Dialog>
            <div className="p-col-12">
                <div className="card card-w-title">
                    <h1>Dispositivos</h1>
                    <DataView ref={el => dv = el}
                        value={devicesState}
                        itemTemplate={itemRow}
                        layout="list"
                        paginatorPosition={'bottom'}
                        paginator={true} rows={10} header={header} />
                </div>
            </div>
        </div>
    );
}

export default Devices;