import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import AppTopbar from './AppTopbar';
import AppFooter from './AppFooter';
import AppMenu from './AppMenu';
import AppProfile from './AppProfile';
import { Route } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import Devices from './pages/Devices';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import './layout/layout.scss';
import './App.scss';
import Account from './pages/Account';

function App() {
    // eslint-disable-next-line
    const [layoutModeState, setLayoutModeState] = useState('static');
    // eslint-disable-next-line
    const [layoutColorModeState, setLayoutColorModeState] = useState('dark');
    const [staticMenuInactiveState, setStaticMenuInactiveState] = useState(false);
    const [overlayMenuActiveState, setOverlayMenuActiveState] = useState(false);
    const [mobileMenuActiveState, setMobileMenuActiveState] = useState(false);
    const [menuState, setMenuState] = useState([]);

    let menuClick;

    useEffect(() => {
        createMenu();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (mobileMenuActiveState) {
            addClass(document.body, 'body-overflow-hidden');
            return;
        }
        removeClass(document.body, 'body-overflow-hidden');
    });

    function onWrapperClick(event) {
        if (!menuClick) {
            setOverlayMenuActiveState(false);
            setMobileMenuActiveState(false);
        }
        menuClick = false;
    }

    function onToggleMenu(event) {
        menuClick = true;

        if (isDesktop()) {
            if (layoutModeState === 'overlay') {
                setOverlayMenuActiveState(!overlayMenuActiveState);
            }
            else if (layoutModeState === 'static') {
                setStaticMenuInactiveState(!staticMenuInactiveState);
            }
        }
        else {
            const mobileMenuActive = mobileMenuActiveState;
            setMobileMenuActiveState(!mobileMenuActive);
        }
        event.preventDefault();
    }

    function onSidebarClick(event) {
        menuClick = true;
    }

    function onMenuItemClick(event) {
        if (!event.item.items) {
            setOverlayMenuActiveState(!overlayMenuActiveState);
            setMobileMenuActiveState(false);
        }
    }

    function createMenu() {
        setMenuState([
            { label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/app' },
            { label: 'Dispositivos', icon: 'pi pi-fw pi-cog', to: '/app/devices' },
        ]);
    }

    function addClass(element, className) {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    function removeClass(element, className) {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    function isDesktop() {
        return window.innerWidth > 1024;
    }

    const wrapperClass = classNames('layout-wrapper', {
        'layout-overlay': layoutModeState === 'overlay',
        'layout-static': layoutModeState === 'static',
        'layout-static-sidebar-inactive': staticMenuInactiveState && layoutModeState === 'static',
        'layout-overlay-sidebar-active': overlayMenuActiveState && layoutModeState === 'overlay',
        'layout-mobile-sidebar-active': mobileMenuActiveState
    });

    const sidebarClassName = classNames("layout-sidebar", {
        'layout-sidebar-dark': layoutColorModeState === 'dark',
        'layout-sidebar-light': layoutColorModeState === 'light'
    });


    return (
        <div className={wrapperClass} onClick={onWrapperClick}>
            <AppTopbar onToggleMenu={onToggleMenu} />

            <div className={sidebarClassName} onClick={onSidebarClick}>
                <AppProfile />
                <AppMenu model={menuState} onMenuItemClick={onMenuItemClick} />
            </div>

            <div className="layout-main">
                <Route path="/app/" exact component={Dashboard} />
                <Route path="/app/account" exact component={Account} />
                <Route path="/app/devices" exact component={Devices} />
            </div>

            <AppFooter />

            <div className="layout-mask"></div>
        </div>
    );
}

export default App;
