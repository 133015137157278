import api from '../../services/api';
import { parseAccount, parsePasswordAccount } from './accountParser';

export const updateProfileAccount = async (name, description, username) => {
    const response = await api.post('/OrganizationAccount', parseAccount(name, description, username));
    return response.data;
};

export const updateProfilePassword = async (oldPassword, password) => {
    const response = await api.post('/OrganizationPassword', parsePasswordAccount(oldPassword, password));
    return response.data;
};

